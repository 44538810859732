





































import { MarketKeyInfo } from '@/blockchainHandlers/ido-contract-interface'
import { PoolStore } from '@/modules/ido/stores/pool-store'
import { promiseHelper } from '@/helpers/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    HoverImage: () => import('./hover-image.vue'),
  },
})
export default class MarketSuccessDialog extends Vue {
  dialog = false
  keyInfo: MarketKeyInfo | null = null
  poolStore: PoolStore | null = null

  resolve?: (any) => void

  async open(keyInfo: MarketKeyInfo, poolStore: PoolStore) {
    this.keyInfo = keyInfo
    this.poolStore = poolStore
    this.dialog = true
    await promiseHelper.delay(100)
    return new Promise((r) => (this.resolve = r))
  }
  async cancel() {
    this.resolve && this.resolve(null)
    this.dialog = false
    await promiseHelper.delay(100)
  }
}
